import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import { BarChart } from '@mui/x-charts/BarChart';
import { axisClasses } from '@mui/x-charts';
import Person2OutlinedIcon from '@mui/icons-material/Person2Outlined';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import BiotechOutlinedIcon from '@mui/icons-material/BiotechOutlined';
import { GetDashboard } from '../Services/Dashboard';
import { Monthly_Lab_Data } from '../Services/Dashboard';
import { GetUser_ForDashboard } from '../Services/Authservices';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import { styled } from '@mui/material/styles';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Card,
  Divider, 
  Dialog,
  DialogTitle,
  DialogContent,
} from '@mui/material';
import  { tableCellClasses } from '@mui/material/TableCell';
import Slide from '@mui/material/Slide';
import SignUp from './SignUp';
import '../UI/Dashboard.css';
import { toast } from 'react-toastify';
import SpinnerModal from '../Spinner/SpinnerModal';
// import { BarChart } from '@mui/x-charts/BarChart';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#5bc5d0',
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  const transitionDuration = 500; // milliseconds
  return <Slide direction="down" ref={ref} {...props} timeout={transitionDuration} />;
});

const chartSetting = {
  xAxis: [
    {
      label: '',
    },
  ],
  width: 400,
  height: 350,
};

const valueFormatter = (value) => `${value}`;

const Dashboard = () => {
  const [dashboardData, setDashboardData] = useState([]);
  const [dataset, setDataset] = useState([]);
  const[userDetails,SetuserDetails] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const[Monthly_Report,SetMonthly_Report] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
 
  
  const openModal = () => {
    setModalIsOpen(true);
  };
  const closeModal = () => {
    setModalIsOpen(false);
  };

  useEffect(() => {
    getDashboardData();
    GetUser();
  }, []);

  const getDashboardData = async () => {
    try{
      setIsLoading(true);
      const LabID = sessionStorage.getItem('LabID');
      const response = await GetDashboard(LabID);
      setDashboardData(response);
    }catch(error){
      toast.error("Unabled to get fianance data",error)
    }finally{
      setIsLoading(false);
    }
   
  };

  const GetUser  = async() =>{
    try{
      setIsLoading(true);
      const LabID = sessionStorage.getItem('LabID');
      const result  = await GetUser_ForDashboard(LabID)
      SetuserDetails(result)
    }catch(error){
     toast.error("Unabled to get user data",error)
    }finally{

    }
   
  }

   //*************** States ********************* */
   const currentYear = new Date().getFullYear();
  const startDate = new Date(currentYear, 0, 1).toISOString().split('T')[0]; 
  const endDate = new Date(currentYear, 11, 31).toISOString().split('T')[0]; 

  const [formData, setFormData] = useState({
    StartDate: startDate,
    EndDate: endDate,
    LabID: sessionStorage.getItem('LabID')
  });
  const onchange = (e) =>{
    debugger
    const {name,value} = e.target;
    setFormData({
        ...formData,
        [name]:value,
    }) 
}

const GetDashboardStates = async () =>{
  try{
    setIsLoading(true);
    const Monthly_Report = await Monthly_Lab_Data(formData);
      SetMonthly_Report(Monthly_Report)
      const newDataset = Monthly_Report.map(item => {
        return {
          month: item.monthName,
          total_monthly_Discount: item.totalDiscount,
          totalGrandTotal: item.totalGrandTotal
        };
      });
      setDataset(newDataset);
  }catch(error){
    toast.error("Unabled to get States data",error)
  }finally{
    setIsLoading(false);
  }
}
useEffect(() =>{
  GetDashboardStates();
},[])
const onApply = () =>{
  GetDashboardStates();
}
  return (
    <>
    {isLoading && <SpinnerModal isLoading={true} />}
      <div className='container-fluid Container2' style={{marginBottom:'5rem'}}>
        <div className='row' style={{ gap: ' 1rem', marginLeft: '1px' }}>
          <Card className='col-5' sx={{ height: 100, textAlign: "Center", boxShadow: 'rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px' }}>
            <PeopleAltIcon sx={{ width: '3rem', height: '3rem' }} style={{ color: '#5bc5d0' }} className='cardICON' />
            <Divider></Divider>
            <Box>
              <h4>Total Patient : {dashboardData.totalPatients}</h4>
            </Box>
          </Card>
          <Card className='col-5' sx={{ height: 100, textAlign: "Center", boxShadow: 'rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px' }}>
            <BiotechOutlinedIcon sx={{ width: '3rem', height: '3rem' }}  style={{ color: '#5bc5d0' }} className='cardICON' />
            <Divider></Divider>
            <Box>
              <h4>Total Active Test : {dashboardData.totalTests}</h4>
            </Box>
          </Card>
        </div>
        <div className='row mt-4' style={{  gap: ' 1rem', marginLeft: '1px' }}> 
          <Card className='col-md-5 p-2' sx={{maxHeight:'22rem', boxShadow: 'rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px'}}>
             <div className='row' style={{alignItems:'center'}}>
                   <div className='col-sm mt-2'>
                    <label className='common-Labels' >Date from</label>
                      <input
                            className="form-control common-Input"
                            id='DateFrom'
                            name='StartDate'
                            required
                            type='date'
                            onChange={onchange}
                            value={formData.StartDate}
                       />
                    </div>
                    <div className='col-sm mt-2'>
                    <label className='common-Labels' >Date To</label>
                      <input
                            className="form-control common-Input"
                            id='DateTo'
                            name='EndDate'
                            required
                            type='date'
                            onChange={onchange}
                            value={formData.EndDate}
                       />
                    </div>
                    <div className='col-sm-3 mt-3'>
                      <button type='button' style={{marginTop:'14px'}} onClick={onApply}  className="common-Apply" >Apply</button>
                    </div>
              </div> 
            <hr></hr>
           <Table sx={{paddingBottom:'5rem'}}  stickyHeader aria-label="sticky table">
                    <TableHead sx={{backgroundColor:'#5bc5d0'}}>
                        <StyledTableRow sx={{backgroundColor:'#5bc5d0'}}>
                            <StyledTableCell className='DTC1'>Year</StyledTableCell>
                            <StyledTableCell className='DTC1'>Month</StyledTableCell>
                            {/* <StyledTableCell className='DTC1'>Patient</StyledTableCell> */}
                            <StyledTableCell className='DTC1'>Tests</StyledTableCell>
                            <StyledTableCell className='DTC1'>Collection</StyledTableCell>
                            <StyledTableCell className='DTC1'>Discount</StyledTableCell>
                        </StyledTableRow>
                    </TableHead>
                    <TableBody>  
                           {Monthly_Report.filter(data => data.totalGrandTotal > 0).map((data, index) => (
                            <StyledTableRow  key={index}>
                             <StyledTableCell className='DTC'>{data.year}</StyledTableCell>
                             <StyledTableCell className='DTC'>{data.monthName}</StyledTableCell>
                             {/* <StyledTableCell className='DTC'>{data.totalPatient}</StyledTableCell> */}
                             <StyledTableCell className='DTC'>{data.totalTest}</StyledTableCell>
                             <StyledTableCell className='DTC'>{data.totalGrandTotal}</StyledTableCell>
                             <StyledTableCell className='DTC'>{data.totalDiscount}</StyledTableCell> 
                            </StyledTableRow>
                            ))}
                    </TableBody>
                </Table>
          </Card>
           <Card className='col-5 P-3'sx={{ boxShadow: 'rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px'}}>
            <h5 style={{top:'1rem',position:'relative'}}>User Details</h5>
            <hr></hr>
          <Table  stickyHeader aria-label="sticky table" >
                    <TableHead>
                        <StyledTableRow>
                            <StyledTableCell className='DTC1'>User Name</StyledTableCell>
                            <StyledTableCell className='DTC1'>Email</StyledTableCell>
                            <StyledTableCell className='DTC1'>Role</StyledTableCell>
                            <StyledTableCell className='DTC1'>
                              <button style={{border:'none',background:'transparent',outline:'none'}} onClick={openModal}><AddCircleRoundedIcon sx={{color:'white'}}/></button>
                            </StyledTableCell>
                        </StyledTableRow>
                    </TableHead>
                    <TableBody>  
                           {userDetails.map((data, index) => (
                            <StyledTableRow  key={index}>
                             <StyledTableCell className='DTC'>{data.firstName} {data.lastName}</StyledTableCell>
                             <StyledTableCell className='DTC'>{data.email}</StyledTableCell>
                             <StyledTableCell className='DTC'>{data.user_Role}</StyledTableCell>
                             <StyledTableCell className='DTC'>
                                <EditRoundedIcon sx={{color:'#5bc5d0'}}/>
                             </StyledTableCell> 
                            </StyledTableRow>
                            ))}
                    </TableBody>
                </Table>

                <Dialog open={modalIsOpen} keepMounted onClose={closeModal}  TransitionComponent={Transition}>
                    <DialogTitle sx={{fontWeight:600,backgroundColor:'#5bc5d0',color:'white'}}>{"Add User"}</DialogTitle>
                    <Divider></Divider>
                    <DialogContent>
                        <SignUp />
                    </DialogContent>
                </Dialog>
                </Card>
        </div>
        <div className='row mt-4' style={{ marginLeft: '1px' }}>
           {dataset.length > 0 && (
              <Card className='col-5 P-3' sx={{ boxShadow: 'rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px' }}>
                <h5 style={{top:'1rem',position:'relative'}}>Graph Statment</h5>
                <hr></hr>
               <BarChart
                  dataset={dataset}
                  yAxis={[{ scaleType: 'band', dataKey: 'month' }]}
                  series={[{ dataKey: 'totalGrandTotal', label: 'monthly collection', valueFormatter }]}
                  layout="horizontal"
                  {...chartSetting}
                />
            </Card>
          )}
         
        </div>
      </div>
    </>
  );
}

export default Dashboard;
