import React, { createContext, useContext, useState } from 'react';

const ApiContext = createContext();

export const ApiProvider = ({ children }) => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isTest_Submitted, setIsTest_Submitted] = useState(false);
  const [isOrderSubmitted, SetisOrderSubmitted] = useState(false);
  const [isReportGenerate, SetisReportGenerate] = useState(false);

  const setSubmissionStatus = (status) => {
    setIsSubmitted(status);
  };

  const setOrderSubmissionStatus = (status) => {
    SetisOrderSubmitted(status);
  };
  const setReport_generation_SubmissionStatus = (status) => {
    SetisReportGenerate(status);
  };

  const setTest_SubmissionStatus = (status) => {
    setIsTest_Submitted(status);
  };

  return (
    <ApiContext.Provider 
     value=
        {{
            isSubmitted,
            setSubmissionStatus,
            isOrderSubmitted,
            setOrderSubmissionStatus,
            isReportGenerate,
            setReport_generation_SubmissionStatus,
            isTest_Submitted,
            setTest_SubmissionStatus
         }}>
      {children}
    </ApiContext.Provider>
  );
};

export const useApi = () => {
  return useContext(ApiContext);
};
