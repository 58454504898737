import { Route, Routes } from 'react-router-dom';
import TestEntry from './Pages/TestEntry';
import PatientList from './Pages/PatientList';
import PatientProfile from './Pages/PatientProfile';
import Dashboard from './Pages/Dashboard';
import SideBar from './Pages/Layout';
import SignUp from './Pages/SignUp';
import SelectTemplate from './Pages/SelectTemplate' 
// Other imports...

function Home() {
    return (
  <>
  </>
          
       
    );
  }
  

export default Home;
