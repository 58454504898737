import React, { useEffect, useState } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { AddUsers } from "../Services/Authservices";
import '../UI/SignUp.css';
import '../UI/TestEntrymodal.css'
import {TextField, MenuItem } from '@mui/material';
import SpinnerModal from "../Spinner/SpinnerModal";
import { toast } from "react-toastify";

const  SignUp = () => {
    const [isLoading, setIsLoading] = useState(false);
    const[formData,setformData] = useState({
        FirstName:'',
        LastName:'',
        Email :'',
        PhoneNumber:'',
        Password:'',
        user_Role:'',
        LaboratoryID : sessionStorage.getItem('LabID'),
    });
    const onchange = (e) =>{
        const {name,value} = e.target;
        setformData({
            ...formData,
            [name]:value,
        }) 
    }
   const onsubmit = async(e) =>{
    e.preventDefault();
    setIsLoading(true)
    try{
        const response = await   AddUsers(formData)
        if(response){
            toast.success("Data saved successfully");
        }
    }
    catch(error){
        console.log(error)
    }
    finally{
        setIsLoading(false)
    }
   } 

   const Role = [
    {
      value: 'Admin',
      label: 'Admin',
    },
    {
      value: 'User',
      label: 'User',
    },
   
  ];
  return (
    <>
    {isLoading && <SpinnerModal isLoading={true} />}
   <div className='container-fluid pt-1'>
       <form onSubmit={onsubmit}>
           <div className='row pt-1'>
                <div  className='col-6 mt-2'>
                   <TextField
                       inputProps={{ className: 'SignUp_input' }}
                       InputLabelProps={{className:'TestEntry_Label' }}
                       label='First Name'
                       className=''
                       id='FirstName'
                       name='FirstName'
                       size='small'
                       required
                       onChange={onchange}
                   ></TextField>
                </div>
                <div  className='col-6 mt-2'>
                   <TextField
                       inputProps={{ className: 'SignUp_input' }}
                       InputLabelProps={{className:'TestEntry_Label' }}
                       label='Las tName'
                       className=''
                       id='LastName'
                       name='LastName'
                       size='small'
                       required
                       onChange={onchange}
                   ></TextField>
                </div>
                <div  className='col-6 mt-2'>
                   <TextField
                       inputProps={{ className: 'SignUp_input' }}
                       InputLabelProps={{className:'TestEntry_Label' }}
                       label='Email'
                       className=''
                       id='Email'
                       name='Email'
                       size='small'
                       required
                       onChange={onchange}
                   ></TextField>
                </div>
                <div  className='col-6 mt-2'>
                   <TextField
                       inputProps={{ className: 'SignUp_input' }}
                       InputLabelProps={{className:'TestEntry_Label' }}
                       label='PhoneNumber'
                       className=''
                       id='PhoneNumber'
                       name='PhoneNumber'
                       size='small'
                       required
                       onChange={onchange}
                   ></TextField>
                </div>
            
                <div  className='col-6 mt-2'>
                   <TextField
                       inputProps={{ className: 'SignUp_input' }}
                       InputLabelProps={{className:'TestEntry_Label' }}
                       label='Password'
                       className=''
                       id='Password'
                       name='Password'
                       size='small'
                       required
                       onChange={onchange}
                   ></TextField>
                </div>
                <div  className='col-6 mt-2'>
                    <TextField
                        inputProps={{ className: 'SignUp_input_dropdown' }}
                        InputLabelProps={{className:'TestEntry_Label' }}
                        label='User Role'
                        id='user_Role'
                        name='user_Role'
                        select
                        size='small'
                        required
                        onChange={onchange}
                        >
                        {Role.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                        {option.label}
                        </MenuItem>
                        ))}
                    </TextField>
                </div>
            </div>
            <hr></hr>
               <div className='row pt-4'>
                 <div  className='col-3'>
                     {/* <Button style={{ backgroundColor: 'rgb(47, 187, 247)',  color: '#FFFFFF'}}
                        type='submit' variant='contained'>
                         Save
                     </Button> */}
                     <button style={{width:'7rem',height:'3.2',borderRadius:'3px',backgroundColor:'#5bc5d0',color:'white',borderRadius:'0px'}}  type='submit' class="btn">Save</button>
                 
                 </div>
               </div>
       </form>
   </div>
   </>
  );
};

export default  SignUp
