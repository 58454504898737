import React, { useState, } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import '../UI/Login.css';
import { Link, useNavigate } from "react-router-dom";
import { GetLogin } from "../Services/Authservices";
import SpinnerModal from "../Spinner/SpinnerModal"; 
import {  TextField, Button, MenuItem   } from '@mui/material';
import image from'../assets/Logo1.png'
import { jwtDecode } from "jwt-decode";
import { toast } from "react-toastify";
const Login = () => {
        const [formData,setformData] =useState({Email:"",Password:""})
        const [isLoading, setIsLoading] = useState(false); 
               const navigate = useNavigate();

        const onchange = (e) =>{
            const {name,value} = e.target;
             setformData({
              ...formData,
              [name]:value,
             })
        }


   const  FormSubmut = async (e) =>{
    e.preventDefault();
    setIsLoading(true)
    try {
      const { token } = await GetLogin(formData);
      sessionStorage.setItem("token", token);

      if (token) {
        const decodedToken = jwtDecode(token); 
        const labId = decodedToken.LabID; // Extracting LabId from the token payload
        const userId = decodedToken.UserId; // Extracting UserId from the token payload
        const User_Role = decodedToken.role;
        const FirstName = decodedToken.FirstName;
        const LastName = decodedToken.LastName;

        sessionStorage.setItem('LabID', labId);
        sessionStorage.setItem('UserID', userId);
        sessionStorage.setItem('User_Role', User_Role);
        sessionStorage.setItem("FirstName",FirstName);
        sessionStorage.setItem("LastName",LastName);
        if(User_Role =='Admin'){
           navigate('/Home/Dashboard');
         }else{
          navigate('/Home/PatientRegistration')
         }
      } else {
        toast.error("Email and password are not correct");
      }
    } catch (error) {
      console.error("Login failed:", error);
      toast.error("Login failed. Please try again.",error);
    } finally {
      setIsLoading(false); 
    }
   }
             
  return (
    <>
    
   
  {isLoading &&  <SpinnerModal isLoading={true} /> } 
 
   <div className="d-flex justify-content-center align-items-center" >
        <div className=" col-md-3 loginForm">
          <div className="PicsDiv">
            <img className="LoginPics" src={image}></img>
          </div>
          <form onSubmit={FormSubmut}>
            <div className="mb-3">
            <label style={{textAlign:'start'}} className='common-Labels' for="email">Email</label>
                  <input
                      className="form-control common-Input"
                      id='email'
                      name='Email'
                      size='small'
                      required
                      onChange={onchange}
                  ></input>
              </div>
              <div className="mb-3">
              <label className='common-Labels' for="password">Password</label>
                  <input
                      className="form-control common-Input"
                      type="password"
                      id='password'
                      name='Password'
                      size='small'
                      required
                      onChange={onchange}
                  ></input>
              </div>
              <div>
              <button type="submit" className="Submit_Button common-FontFamily common-FontWeight">Login</button>
              </div>
          </form>
     </div>
   </div>  
</>
  );
};

export default Login;
