import * as React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import SearchIcon from '@mui/icons-material/Search';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import BiotechRoundedIcon from '@mui/icons-material/BiotechRounded';
import DashboardRoundedIcon from '@mui/icons-material/DashboardRounded';
import PersonAddAltRoundedIcon from '@mui/icons-material/PersonAddAltRounded';
import NoteAltRoundedIcon from '@mui/icons-material/NoteAltRounded';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import { Avatar, Menu, MenuItem } from '@mui/material';
import { GetDashboard } from '../Services/Dashboard';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { GloabalSearchPatient } from '../Services/PatientServicces';
import { useState } from 'react';
import { toast } from 'react-toastify';
import SpinnerModal from '../Spinner/SpinnerModal';
import '../UI/Layout.css';

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme }) => ({
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
}));

const Layout = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const theme = useTheme();
  const [dashboardData, setDashboardData] = React.useState([]);
  const [User_Role, SetUser_Role] = React.useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [FirstN, setFirstName] = React.useState([]);
  const [LastN, setLastName] = React.useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [inputdata, setInputData] = useState('');

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const HandleLogout = () => {
    sessionStorage.clear();
    navigate('/');
  };

  const GetLabDetails = async () => {
    const LabID = sessionStorage.getItem('LabID');
    const response = await GetDashboard(LabID);
    setDashboardData(response);
  };

  React.useEffect(() => {
    const User_Role = sessionStorage.getItem('User_Role');
    SetUser_Role(User_Role);
    GetLabDetails();
    const FirstName = sessionStorage.getItem('FirstName');
    const LastName = sessionStorage.getItem('LastName');
    const avatarFirstName = FirstName?.charAt(0);
    const avatarLastName = LastName?.charAt(0);
    setFirstName(avatarFirstName);
    setLastName(avatarLastName);
  }, []);

  const HanldenavigateToDashboard = () => {
    navigate('/Home/Dashboard');
  };

  const Hanldenavigate = () => {
    navigate('/Home/PatientRegistration');
  };

  const HanldenavigatetoTest = () => {
    navigate('/Home/TestEntry');
  };

  const HanldenavigatetoPatient = () => {
    navigate('/Home/PatientList');
  };

  const HanldenavigatetoTemplate = () => {
    navigate('/Home/TemplateSetting');
  };

  const isActive = (path) => {
    return location.pathname === path;
  };

  const handleChange = (e) => {
    const value = e.target.value;
    setInputData(value);
  };

  const hnadleSearchPatient = async () => {
    setIsLoading(true);
    try {
      const LabID = sessionStorage.getItem('LabID');
      const result = await GloabalSearchPatient(LabID, inputdata);
      if (result.length) {
        const id = result?.[0]?.patientID;
        navigate(`/Home/PatientProfile/${id}`);
      } else {
        toast.error('there is no patient with this name');
      }
    } catch (error) {
      console.log('error while searching patient', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {isLoading && <SpinnerModal isLoading={isLoading} />}
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar
          sx={{
            color: 'black',
            background: '#ffff',
            boxShadow: 'rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px;',
          }}
          position="fixed"
        >
          <Toolbar>
            <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{ color: '#5bc5d0', fontFamily: 'fantasy' }}
            >
              {dashboardData?.labDetails?.laboratoryName}
            </Typography>
            {/* <Box
              sx={{
                display: { xs: 'none', md: 'flex' },
                border: '1px solid gray',
                borderRadius: '0px',
                width: 300,
                marginLeft: '18rem',
              }}
            >
              <button
                className="searchButton"
                style={{
                  border: 'none',
                  padding: '7px 15px',
                  fontSize: 'smaller',
                  color: 'white',
                  backgroundColor: '#5bc5d0',
                  cursor: 'pointer',
                  transition: 'background-color 0.3s ease', // Smooth transition for hover effect
                }}
                onClick={hnadleSearchPatient}
              >
                <SearchIcon sx={{ color: 'white' }} />
              </button>
              <input
                style={{ border: 'none', outline: 'none', fontSize: 'smaller', color: 'gray', width: '70%' }}
                onKeyDown={(e) => { if (e.key === 'Enter') hnadleSearchPatient(); }}
                onChange={handleChange}
                placeholder="Patient Name/contact Number"
              />
            </Box> */}
            <Box sx={{ flexGrow: 1 }} />
            <List sx={{ display: 'flex' }}>
              {User_Role === 'Admin' && (
                <ListItem onClick={HanldenavigateToDashboard} disablePadding>
                  <ListItemButton sx={{ fontSize: 'x-small', display: 'flex', flexDirection: 'column' }}>
                    <ListItemIcon sx={{ minWidth: '0px' }}>
                      <DashboardRoundedIcon sx={{ color: isActive('/Home/Dashboard') ? '#5bc5d0' : '#727586' }} />
                    </ListItemIcon>
                    <ListItemText
                      primary="Dashboard"
                      primaryTypographyProps={{ fontSize: 'x-small', textAlign: 'center' }}
                      sx={{ color: isActive('/Home/Dashboard') ? '#5bc5d0' : '#727586', mt: 1 }}
                    />
                  </ListItemButton>
                </ListItem>
              )}
              <ListItem onClick={Hanldenavigate} disablePadding>
                <ListItemButton sx={{ fontSize: 'x-small', display: 'flex', flexDirection: 'column' }}>
                  <ListItemIcon sx={{ minWidth: '0px' }}>
                    <PersonAddAltRoundedIcon sx={{ color: isActive('/Home/PatientRegistration') ? '#5bc5d0' : '#727586' }} />
                  </ListItemIcon>
                  <ListItemText
                    primary="Registration"
                    primaryTypographyProps={{ fontSize: 'x-small', textAlign: 'center' }}
                    sx={{ color: isActive('/Home/PatientRegistration') ? '#5bc5d0' : '#727586', mt: 1 }}
                  />
                </ListItemButton>
              </ListItem>
              <ListItem onClick={HanldenavigatetoPatient} disablePadding>
                <ListItemButton sx={{ fontSize: 'x-small', display: 'flex', flexDirection: 'column' }}>
                  <ListItemIcon sx={{ minWidth: '0px' }}>
                    <PersonRoundedIcon sx={{ color: isActive('/Home/PatientList') ? '#5bc5d0' : '#727586' }} />
                  </ListItemIcon>
                  <ListItemText
                    primary="Patient"
                    primaryTypographyProps={{ fontSize: 'x-small', textAlign: 'center' }}
                    sx={{ color: isActive('/Home/PatientList') ? '#5bc5d0' : '#727586', mt: 1 }}
                  />
                </ListItemButton>
              </ListItem>
              <ListItem onClick={HanldenavigatetoTest} disablePadding>
                <ListItemButton sx={{ fontSize: 'x-small', display: 'flex', flexDirection: 'column' }}>
                  <ListItemIcon sx={{ minWidth: '0px' }}>
                    <BiotechRoundedIcon sx={{ color: isActive('/Home/TestEntry') ? '#5bc5d0' : '#727586' }} />
                  </ListItemIcon>
                  <ListItemText
                    primary="Test"
                    primaryTypographyProps={{ fontSize: 'x-small', textAlign: 'center' }}
                    sx={{ color: isActive('/Home/TestEntry') ? '#5bc5d0' : '#727586', mt: 1 }}
                  />
                </ListItemButton>
              </ListItem>
              <ListItem onClick={HanldenavigatetoTemplate} disablePadding>
                <ListItemButton sx={{ fontSize: 'x-small', display: 'flex', flexDirection: 'column' }}>
                  <ListItemIcon sx={{ minWidth: '0px' }}>
                    <NoteAltRoundedIcon sx={{ color: isActive('/Home/TemplateSetting') ? '#5bc5d0' : '#727586' }} />
                  </ListItemIcon>
                  <ListItemText
                    primary="Templates"
                    primaryTypographyProps={{ fontSize: 'x-small', textAlign: 'center' }}
                    sx={{ color: isActive('/Home/TemplateSetting') ? '#5bc5d0' : '#727586', mt: 1 }}
                  />
                </ListItemButton>
              </ListItem>
            </List>
            <Box style={{ marginLeft: 'auto' }}>
              <Avatar sx={{ bgcolor: '#5bc5d0', cursor: 'pointer' }} onClick={handleMenuOpen}>
                {FirstN + LastN}
              </Avatar>
            </Box>
          </Toolbar>
          <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
            <MenuItem sx={{ fontSize: 'x-small' }} onClick={HandleLogout}>
              <LockOutlinedIcon />LogOut
            </MenuItem>
          </Menu>
        </AppBar>
      </Box>
    </>
  );
};

export default Layout;
