// AuthGuard.js
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Layout from './Layout';
import Footer from './Footer';

const AuthGuard = ({ children }) => {
  const navigate = useNavigate();

  useEffect(() => {
    const token = sessionStorage.getItem('token');
    if (!token) {
      navigate('/');
    }
  }, [navigate]);
  return <> 
      <Layout />
      <main style={{marginTop:'18vh'}}>
        {children}
      </main>
         
  </>;
};

export default AuthGuard;
