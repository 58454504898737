import React from 'react'
import { useNavigate } from 'react-router-dom';
import  {useCallback, useState} from 'react'
import { PatientRegistration_LabOrders } from '../Services/PatientServicces';
import { Card , Divider  } from '@mui/material';
//   import SendIcon from '@mui/icons-material/Send';
import "../UI/Addpatientmodal.css"
import SpinnerModal from '../Spinner/SpinnerModal';
import LabOrder from './LabOrder';
import { toast } from 'react-toastify';
import '../UI/Common.css'


const PatientRegistration = () =>{
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false); 
  const [IsLaBOrder,setisLabOrder] = useState(false);
  const [LabOrderData, setlabOrderData] = useState({})

    const[formData,setformData] = useState({
        PatientName:'',
        ReferredBy:'',
        ContactNumber:'',
        gender:'male',
        Age:'',
        Comments :'',
        DOB:'',
        Address:'',
        LaboratoryID: sessionStorage.getItem('LabID')
    });
    
    const onchange = (e) =>{
        const {name,value} = e.target;
        setformData({
            ...formData,
            [name]:value,
        }) 
    }


    const handleOrderFormSubmit = useCallback((orderFormData) => {
      if (orderFormData) {
        setisLabOrder( true);
        setlabOrderData(orderFormData);
      } else {
        setisLabOrder( false);
      }
    }, []);
    

   
  const SubmitForm = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
        const response = await PatientRegistration_LabOrders(formData, LabOrderData);
        if (response) {
          toast.success('Patient added successfully');
          navigate('/Home/PatientList')
        }
    } catch (error) {
      console.log('Something went wrong:', error);
      toast.error('Something went wrong while adding patient');
    } finally {
      setIsLoading(false);
    }
  };
    
     
    const Gender = [
        {
          value: 'male',
          label: 'Male',
        },
        {
          value: 'female',
          label: 'Female',
        },
       
      ];
    return (
    <>  
  {isLoading && <SpinnerModal isLoading={true} />}
   <div className='container-fluid Container2'>
      <Card sx={{padding:'10px',width: '100%', overflow: 'hidden' ,minHeight:'80vh',border:'1px solid lightgray',boxShadow:'rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px;'}}>
        <form style={{minHeight:'55vh'}} onSubmit={SubmitForm}>
            <div className='row'>
              <div>
                <h5 className='common-FontWeight common-FontFamily'>Patient Reqistration</h5>
              </div>
                <div  className='col-3 mt-2'>
                <label className='common-Labels' for="PatientName">Patient Name</label>
                 <input
                        className="form-control common-Input"
                        id='PatientName'
                        name='PatientName'
                        size='small'
                        required
                        onChange={onchange}
                    ></input>
                </div>
                <div className='col-3 mt-2'>
                <label className='common-Labels' for="ReferredBy">ReferredBy</label>
                        <input
                        className="form-control common-Input"
                        id='ReferredBy'
                        size='small'
                        name='ReferredBy'
                        required
                        onChange={onchange} />
                </div>
              <div  className='col-3 mt-2'>
              <label className='common-Labels' for="ContactNumber">Contact Number</label>
                        <input
                            className="form-control common-Input"
                          id='ContactNumber'
                          name='ContactNumber'
                          size='small'
                         onChange={onchange} />
                  </div> 
                
                <div  className='col-3 mt-2'>
                <label className='common-Labels' for="gender">gender</label><br></br>
                <select
                   className="form-control common-Input"
                   id='gender'
                   name='gender'
                   select
                   size='small'
                   required
                  onChange={onchange}
                 >
                  {Gender.map((option) => (
                 <option key={option.value} value={option.value}>
                   {option.label}
                 </option>
                ))}
               </select>
                   
                </div>
                <div  className='col-3 mt-2'>
                <label className='common-Labels' for="Age">Age</label>
                        <input
                          className="form-control common-Input"
                          id='Age'
                          name='Age'
                          size='small'
                         onChange={onchange} />
                  </div>
                
                  <div  className='col-3 mt-2'>
                  <label className='common-Labels' for="Comments">Comments</label>
                        <input
                          className="form-control common-Input"
                          id='Comments'
                          name='Comments'
                          size='small'
                         onChange={onchange} />
                  </div>
                 
                 <div  className='col-3 mt-2'>
                 <label className='common-Labels' for="DOB">DOB</label>
                        <input
                          className="form-control common-Input"
                          id='DOB'
                          name='DOB'
                          size='small'
                         onChange={onchange} />
                  </div>
                  <div  className='col-3 mt-2'>
                  <label className='common-Labels' for="Address">Address</label>
                        <input
                          className="form-control common-Input"
                          id='Address'
                          name='Address'
                          size='small'
                         onChange={onchange} />
                  </div>
                 </div>
                 
                <Divider sx={{color:'black',marginTop:'1rem'}}></Divider>
                <div style={{position:'relative',left:'-12px',minHeight:'17rem'}}>
                   <LabOrder   onSubmit={handleOrderFormSubmit}/>
                </div>
                <div className='row'>
                  <div  className='col-3'>
                    <button   type='submit' className="btn common-button">Save</button>
                  </div>
                </div>
        </form>
        </Card>  
    </div>
    </>
        
    )
}
export default PatientRegistration;