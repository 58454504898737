import React, { useState, useEffect } from "react";
import { Add_TestSettings } from "../Services/TestServices";
import { toast } from "react-toastify";

const TestSettings = () => {
  const [formData, setFormData] = useState({
    CataGory_Value: '',
    sub_Categorry: '',
    Age_Value: '',
    LabID: sessionStorage.getItem('LabID'),
  });
  
  

  const onChange = (e) => {
    const { value, name } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

 

 

  const save = async (e) => {
    e.preventDefault();
    try {
      const response = await Add_TestSettings(formData);
      if (response) {
        toast.success("Data saved successfully");
      } else {
        toast.error("Something went wrong");
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <>
      <div className="container-fluid" style={{ width: '33rem',border:'1px solid lightgray',padding:'20px' }}>
      <h5 className="common-FontSize-small common-FontFamil">Note:You can add values individually, one at a time .</h5>
        <hr></hr>
        <div className="row">
          <div className='col-sm-4'>
            <label className='common-Labels' htmlFor="CataGory_Value">Category Value:</label>
          </div>
           <div className="col-sm-6">  
            <input
              className="form-control form-control common-Input"
              id='CataGory_Value'
              name='CataGory_Value'
              value={formData.CataGory_Value}
              onChange={onChange}
            />
          </div>
        </div>
        <div className="row">
          <div className='col-sm-4'>
            <label className='common-Labels' htmlFor="sub_Categorry">Sub Category:</label>
          </div>
          <div className="col-sm-6 mt-2"> 
            <input
              className="form-control form-control common-Input"
              id='sub_Categorry'
              name='sub_Categorry'
              value={formData.sub_Categorry}
              onChange={onChange}
            />
          </div>
          <div className='col-sm-4'>
            <label className='common-Labels' htmlFor="Age_Value">Age Value:</label>
          </div>
          <div className="col-sm-6 mt-2">
             <input
              className="form-control form-control common-Input"
              id='Age_Value'
              name='Age_Value'
              value={formData.Age_Value}
              onChange={onChange}
            />
          </div>
        </div>
        <div className="row">
          <div className='col-sm-3 mt-4'>
            <button onClick={save} className="btn common-button" >Save</button>
          </div>
        </div>
      </div>
    </>
  );
};

export default TestSettings;
