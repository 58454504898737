import React, { useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import AuthGuard from './Pages/AuthGuard';
import Login from './Pages/Login';
// import { AuthProvider } from './Pages/AuthContext'; 
import Home from './Home';
import { ApiProvider } from './ContextApi/ContextApi';
import AccountRegistration from './Pages/AccountRegistrationForm';
import TestEntry from './Pages/TestEntry';
import PatientList from './Pages/PatientList';
import PatientProfile from './Pages/PatientProfile';
import Dashboard from './Pages/Dashboard';
import SignUp from './Pages/SignUp';
import TemplateSetting from './Pages/TemplateSetting' 
import PatientRegistration from './Pages/PatientRegistration';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  const [User_Roles,SetUser_Role] = React.useState([]);

  return (
    <>
    <BrowserRouter>
      {/* <AuthProvider> */}
        <ApiProvider>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/AccountRegistration" element={<AccountRegistration />} />
          <Route
            path="/Home/*"
            element={
              <AuthGuard>
                  <Routes>
                   <Route path="Dashboard" element={<Dashboard />} /> 
                   <Route path="SignUp" element={<SignUp />} />
                   <Route path="TestEntry" element={<TestEntry />} />
                   <Route path="PatientList" element={<PatientList />} />
                   <Route path="PatientProfile/:id" element={<PatientProfile />} />
                   <Route path='TemplateSetting' element={<TemplateSetting />} />
                   <Route path='PatientRegistration' element={<PatientRegistration />} />
                 </Routes>
              </AuthGuard>
            }
          />
        </Routes>
        </ApiProvider>
      {/* </AuthProvider> */}
    </BrowserRouter>
    <ToastContainer
      position="top-left"
      autoClose={1000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="colored"
      />
    </> 
  );
}

export default App;
