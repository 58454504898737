import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
} from '@mui/material';
import moment from 'moment/moment';
import QRCode from 'react-qr-code';  
import '../UI/PrintReport.css';

const Template2 = ({ reportData ,DRS,FontStyle,QRurl}) => {
  const currentDateTime = new Date();
  const formattedCurrentDate = moment(currentDateTime).format('D MMM YYYY | HH:mm:ss');
  const username = sessionStorage.getItem("FirstName") + sessionStorage.getItem("LastName");

  const groupByCategoryAndSubCategory = (data) => {
    return data.reduce((result, item) => {
      const category = item.categorY_CODE;
      const subCategory = item.suB_CATEGORY_CODE;

      if (!result[category]) {
        result[category] = {
          categoryValue: item.categorY_VALUE,
          subCategories: {},
        };
      }

      if (!result[category].subCategories[subCategory]) {
        result[category].subCategories[subCategory] = {
          subCategoryValue: item.suB_CATEGORY_VALUE,
          items: [],
        };
      }

      result[category].subCategories[subCategory].items.push(item);
      return result;
    }, {});
  };
  const groupedData = groupByCategoryAndSubCategory(reportData);

  // Function to format normal range and unit values
  const formatTextWithLineBreaks = (text) => {
    return text.split('\n').map((line, index) => (
      <React.Fragment key={index}>
        {line}
        {index < text.split('\n').length - 1 && <br />}
      </React.Fragment>
    ));
  };

  const firstComment = reportData.find((x) => x.comment != '' && x.comment != null)?.comment;
  const qrCodeValue = QRurl ;
  return (
    <>
      <div style={{backgroundColor:'white'}}>
        <div style={{ width: '100%'}}>
          <img className="logo" src={"data:image/png;base64," + reportData?.[0]?.data} alt="Lab Logo" style={{ width: '100%', height: '130px', objectFit: 'contain' }} />
        </div>
<div style={{  fontFamily:FontStyle[0]?.fontfamily? FontStyle[0]?.fontfamily : 'Georgia serif',padding:'10px',fontSize:FontStyle[0]?.fontsize? FontStyle[0]?.fontsize : '10px' ,fontweight:'bold'}}>
    <Grid container>
      <Grid item xs={4}>
        <div>
          <div style={{ textAlign: 'start' }}>
            <span style={{ fontWeight: 'bold' }}>MR No:</span>&nbsp;<span>{reportData?.[0]?.patientID}-{sessionStorage.getItem('LabID')}</span>
          </div>
          <div style={{ textAlign: 'start' }}>
            <span style={{ fontWeight: 'bold' }}>Patient Name:</span>&nbsp;<span>{reportData?.[0]?.patientName}</span>
          </div>
          <div style={{ textAlign: 'start' }}>
            <span style={{ fontWeight: 'bold' }}>
              {reportData?.[0]?.sex === 'male' ? 'Father Name :' : 'Father / Husband :'}
            </span> &nbsp; <span></span>
          </div>
          <div style={{ textAlign: 'start' }}>
            <span style={{ fontWeight: 'bold' }}>Age|Gender:</span>&nbsp;<span>{reportData?.[0]?.age} Years | {reportData?.[0]?.sex}</span>
          </div>
          <div style={{ textAlign: 'start' }}>
            <span style={{ fontWeight: 'bold' }}>Address:</span>&nbsp;<span>{reportData?.[0]?.address}</span>
          </div>
        </div>
      </Grid>
      
      <Grid item xs={4}>
        <div>
          <div style={{ textAlign: 'start' }}>
            <span style={{ fontWeight: 'bold' }}>Phone Number:</span>&nbsp;<span>{reportData?.[0]?.contactNumber}</span>
          </div>
          <div style={{ textAlign: 'start' }}>
            <span style={{ fontWeight: 'bold' }}>Referred by:</span>&nbsp;<span>{reportData?.[0]?.referredBy}</span>
          </div>
          <div style={{ textAlign: 'start' }}>
            <span style={{ fontWeight: 'bold' }}>Registration Date&Time:</span>&nbsp;<span>{moment(reportData?.[0]?.createdAt).format('D MMM YYYY | HH:mm:ss')}</span>
          </div>
        </div>
      </Grid>
      <Grid item xs={4} style={{ display: 'flex', flexDirection: 'column' }}>
        <QRCode value={qrCodeValue} size={100} />
        <span className='common-FontSize-small'>Scan to Download</span>
      </Grid>
   </Grid>
<hr style={{ marginTop: 0}} />

          {/* Table Section */}
          {Object.keys(groupedData).map((category, index) => (
            <div key={index}>
                <h2  style={{ textAlign: 'center',fontSize:FontStyle[0].fontsize? FontStyle[0].fontsize:'12px',fontweight:'900'}}>{groupedData[category].categoryValue}</h2>
                {Object.keys(groupedData[category].subCategories).map((subCategory, subIndex) => (
                <div key={subIndex}>
                  <span className='common-FontWeight'>{groupedData[category].subCategories[subCategory].subCategoryValue}</span>
                  <table style={{ width: '100%', tableLayout: 'fixed' }}>
                  <thead style={{ backgroundColor: '#f8f8ff', }}>
                    <tr style={{ textAlign: 'start' }}>
                      <th style={{ width: '40%' }}>Test Description</th>
                      <th style={{ width: '20%' }}>Normal Range</th>
                      <th style={{ width: '20%' }}>Unit</th>
                      <th style={{ width: '20%' }}>Result</th>
                    </tr>
                  </thead>
                  <tbody style={{ textAlign: 'start' }}>
                    {groupedData[category].subCategories[subCategory].items.map((data, index) => (
                      <tr key={index}>
                        <td>{data.testName}</td>
                        <td>{formatTextWithLineBreaks(data.normalRange)}</td>
                        <td>{formatTextWithLineBreaks(data.unit)}</td>
                        <td style={{ fontWeight: 'bold' }}>{data.testResult}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                </div>
              ))}
            </div>
          ))}
         <div>
         <hr style={{ marginTop: 0,marginBottom:0}} />
         </div>
          <div className='d-flex justify-content-between'>
              <span className='col-6'>
                  <span>Printed at: {formattedCurrentDate}</span> &nbsp; <span>Printed By: {username}</span>
              </span>
              <span  className='col-6'>
                  {firstComment && (
                    <div>
                    <span>Comment:</span>
                    <span>{firstComment}</span>
                  </div>
                    )}
              </span>
          </div>
        </div>
           
         
      </div>
      <footer style={{alignItems:'center',fontFamily:FontStyle[0]?.fontfamily? FontStyle[0]?.fontfamily :  'Georgia serif', padding: '10px', marginTop: '20px', position:'relative',top:'60px' }}>
        <div>
          <p></p>
        </div>
        <table style={{ width: '100%', tableLayout: 'auto' }}>
            <tbody>
              <tr>
                {DRS.map((data, index) => (
                  <td key={index} style={{ padding: '10px', verticalAlign: 'top', wordWrap: 'break-word', fontFamily:FontStyle[0]?.fontfamily? FontStyle[0]?.fontfamily :  'Georgia serif',fontSize:FontStyle[0]?.fontsize? FontStyle[0]?.fontsize : '12px' }}>
                    <div style={{fontweight:'bold'}}>{data.drname}</div>
                    <div>{data.designation}</div>
                    <div>{data.description}</div>
                  </td>
                ))}
              </tr>
            </tbody>
          </table>
       
      </footer>
    </>
  );
};

export default Template2;
