import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
} from '@mui/material';
import moment from 'moment/moment';
import QRCode from 'react-qr-code';  
import '../UI/PrintReport.css';

const Template1 = ({ reportData,FontStyle,QRurl }) => {
  const currentDateTime = new Date();
  const formattedCurrentDate = moment(currentDateTime).format('D MMM YYYY | HH:mm:ss');
  const username = sessionStorage.getItem("FirstName") + sessionStorage.getItem("LastName");

  const groupByCategoryAndSubCategory = (data) => {
    return data.reduce((result, item) => {
      const category = item.categorY_CODE;
      const subCategory = item.suB_CATEGORY_CODE;

      if (!result[category]) {
        result[category] = {
          categoryValue: item.categorY_VALUE,
          subCategories: {},
        };
      }

      if (!result[category].subCategories[subCategory]) {
        result[category].subCategories[subCategory] = {
          subCategoryValue: item.suB_CATEGORY_VALUE,
          items: [],
        };
      }

      result[category].subCategories[subCategory].items.push(item);
      return result;
    }, {});
  };

  const groupedData = groupByCategoryAndSubCategory(reportData);

  // Function to format normal range and unit values
  const formatTextWithLineBreaks = (text) => {
    return text.split('\n').map((line, index) => (
      <React.Fragment key={index}>
        {line}
        {index < text.split('\n').length - 1 && <br />}
      </React.Fragment>
    ));
  };

  const firstComment = reportData.find((x) => x.comment != '' && x.comment != null)?.comment;
  const qrCodeValue = QRurl ;
  return (
    <>
      <div>
        <div style={{ width: '100%', marginBottom: '20px' }}>
          <img className="logo" src={"data:image/png;base64," + reportData?.[0]?.data} alt="Lab Logo" style={{ width: '100%', height: '130px' , objectFit: 'contain'}} />
        </div>
        <div style={{ padding: '1rem', fontFamily:FontStyle[0]?.fontfamily? FontStyle[0]?.fontfamily :"sans-serif", fontSize:FontStyle[0]?.fontsize? FontStyle[0]?.fontsize :'10px' }}>
          <Grid container spacing={8}>
            <Grid item xs={6}>
              <div style={{ textAlign: 'start' }}>
                <span style={{ fontSize:FontStyle[0]?.fontsize? FontStyle[0]?.fontsize :'13px', fontFamily: FontStyle[0]?.fontfamily? FontStyle[0]?.fontfamily:'sans-serif' }}>
                  {reportData?.[0]?.laboratoryAddress}, {reportData?.[0]?.laboratoryEmail}
                </span><br />
                <span style={{ fontSize: FontStyle[0]?.fontsize? FontStyle[0]?.fontsize :'12px', fontFamily:FontStyle[0]?.fontfamily? FontStyle[0]?.fontfamily:'sans-serif' }}>
                  Contact: {reportData?.[0]?.laboratoryContactNumber}
                </span><br />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div style={{ float: 'right' }}>
                <div style={{ textAlign: 'end' }}>
                  <span className='Template3_Patient_Info'>Mr No:&nbsp;{reportData?.[0]?.patientID}-{sessionStorage.getItem('LabID')}</span>
                </div>
                <div style={{ textAlign: 'end' }}>
                  <span className='Template3_Patient_Info'>Patient Name:&nbsp;{reportData?.[0]?.patientName}</span>
                </div>
                <div style={{ textAlign: 'end' }}>
                  <span className='Template3_Patient_Info'>Age|Gender:&nbsp;{reportData?.[0]?.age}Years|{reportData?.[0]?.sex}</span>
                </div>
                <div style={{ textAlign: 'end' }}>
                  <span className='Template3_Patient_Info'>Phone Number:&nbsp;{reportData?.[0]?.contactNumber}</span>
                </div>
                <div style={{ textAlign: 'end' }}>
                  <span className='Template3_Patient_Info'>Referred by:&nbsp;{reportData?.[0]?.referredBy}</span>
                </div>
              </div>
            </Grid>
          </Grid>
          <div>
            <span>Reported At: {moment(reportData?.[0]?.createdAt).format('D MMM YYYY | HH:mm:ss')}</span>
          </div>

          {/* Table Section */}
          {Object.keys(groupedData).map((category, index) => (
            <div key={index}>
               <div style={{border:'1px solid lightgray'}}>
                  <p className='common-FontWeight' style={{ textAlign: 'center' ,marginTop:'10px'}}>{groupedData[category].categoryValue}</p>
               </div> 
              {Object.keys(groupedData[category].subCategories).map((subCategory, subIndex) => (
                <div key={subIndex}>
                  <span className='common-FontWeight ' style={{ textAlign: 'left' }}>{groupedData[category].subCategories[subCategory].subCategoryValue}</span>
                  <table style={{ width: '100%' }}>
                    <thead>
                      <tr>
                        <th>Test Description</th>
                        <th>Result</th>
                        <th>Unit</th>
                        <th>Normal Range</th>
                      </tr>
                    </thead>
                    <tbody>
                      {groupedData[category].subCategories[subCategory].items.map((data, index) => (
                        <tr key={index}>
                          <td>{data.testName}</td>
                          <td style={{ fontWeight: 'bold' }}>{data.testResult}</td>
                          <td>{formatTextWithLineBreaks(data.unit)}</td>
                          <td>{formatTextWithLineBreaks(data.normalRange)}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  {/* <hr /> */}
                </div>
              ))}
            </div>
          ))}
          <hr></hr>
          <div className='d-flex justify-content-between'>
            <span>
                <span>Printed at: {formattedCurrentDate}</span> &nbsp; <span>Printed By: {username}</span>
            </span>
             <span>
                {firstComment && (
                  <div>
                  <span>Comment:</span>
                  <span>{firstComment}</span>
                </div>
                  )}
             </span>
          </div>      
        </div>

            <div style={{ textAlign: 'center', marginTop: '20px' }}>
              <QRCode value={qrCodeValue} size={150} />
              <p>Scan to Download PDF</p>
            </div>
      </div>
    </>
  );
};

export default Template1;
