import axios  from "axios";

// const baseURL = 'https://localhost:44397/api/'; //local
const baseURL = 'https://securelab.api.sehhasolutions.com/api/'; //prod


const apiServices = axios.create({
    baseURL,
})

export  const PatientRegistration_LabOrders = async (data,Order) =>{
    try{
        const response  = await apiServices.post('Home/PatientRegistration',
          {
              patient: {
                    PatientID:data.PatientID ? data.PatientID :null,
                    PatientName:data.PatientName,
                    ReferredBy: data.ReferredBy,
                    ContactNumber:data.ContactNumber,
                    Sex:data.gender,
                    Age:data.Age,
                    Comments :data.Comments,
                    // DOB:data.DOB,
                    Address:data.Address,
                    LaboratoryID:data.LaboratoryID
                },
                laborder: Order.map(order => ({
                    TestID: order.TestID,
                    GrandTotal: order.GrandTotal,
                    TotalBill: order.TotalBill,
                    Discount: order.Discount,
                    CreatedBy: sessionStorage.getItem('UserID'),
                    UpdatedBy: sessionStorage.getItem('UserID'),
                    LaboratoryID: sessionStorage.getItem('LabID'),
                }))
          } 
        )
        return response;
    }
    catch(error){
        throw error
       }
}
export  const RetrivePatientsData = async (LabID) =>{
        try{
            const response  = await apiServices.get(`/Home/GetPatient?LabID=${LabID}`)
            return response.data;
        }
        catch(error){
            throw error
           }
    }
    export  const GetSinglepatient = async (id,LabID) =>{
        
        try{
            const response  = await apiServices.get(`/Home/GetSinglePatient?id=${id}&LabID=${LabID}`)
            
            return response.data;
        }
        catch(error){
            throw error
           }
    }

    export  const GloabalSearchPatient = async (id,name) =>{
        
        try{
            const response  = await apiServices.get(`/Home/PatientGloablSearch?labid=${id}&Name=${name}`)
            return response.data;
        }
        catch(error){
            throw error
           }
    }
    export  const DeletePatient = async (LabiD,PatientID) =>{
        
        try{
            const response  = await apiServices.delete(`/Home/DeletePatient?LabiD=${LabiD}&PatientID=${PatientID}`)
            return response.data;
        }
        catch(error){
            throw error
           }
    }