import React, {useEffect, useState} from 'react'
import { RetriveTest,DeleteTest,Get_Categorry,Get_AgeGroup,Get_SubCategory } from '../Services/TestServices';
import TestRegistration from '../Pages/TestRegistration';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import {  
    Table,
    TableBody, 
    TableCell, 
    TableContainer, 
    TableHead, 
    TableRow, 
    Paper, 
    TablePagination,
    Divider,
    Box,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
  } from '@mui/material';
  import Slide from '@mui/material/Slide';
  import SpinnerModal from '../Spinner/SpinnerModal';
  import { useApi } from '../ContextApi/ContextApi';
import { toast } from 'react-toastify';
import { styled } from '@mui/material/styles';
import  { tableCellClasses } from '@mui/material/TableCell';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import TestSettings from './TestSettings';
import '../UI/TestEntry.css'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#5bc5d0',
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));
  const Transition = React.forwardRef(function Transition(props, ref) {
    const transitionDuration = 500; // milliseconds
    return <Slide direction="down" ref={ref} {...props} timeout={transitionDuration} />;
  });
  
const TestEntry = () =>{
    const { isTest_Submitted,setTest_SubmissionStatus } = useApi();
    const [isLoading, setIsLoading] = useState(false);
    const [TesData,setTestData] = useState([]);
    const [selectedTestId, setSelectedTestId] = useState(null);
    const [DeletedTestId, setDeletedTestId] = useState(null);
    const [isDelete,SetisDelete] = useState(false);
    const[CategorryData,SetCateGorryData] = useState([]);
    const[AgegroupData,SetAgegroupData] = useState([]);
    const[SubCategory,SetSubCategory] = useState([]);
    const[UserRole,SetUserRole] = useState(null);
    const [Params,setParams] = useState({
        CATEGORY_CODE:'',
        GenderCategory:'',
        AGE_CODE:'',
        LabID:sessionStorage.getItem('LabID'),
        SUB_CATEGORY_CODE:'',
    })

//-------------------retrive All test including filters----------------
     const RetriveTestData = async ()=>{
        setIsLoading(true);
        try{
            const Response = await RetriveTest(Params);
            setTestData(Response)
            setTest_SubmissionStatus(false)
        }
        catch(error){
            console.log(error)
        }
        finally{
            setIsLoading(false)
        }
     }
    useEffect(() => {
        RetriveTestData();
    },[isTest_Submitted])
    
//get Test ID
const handleEditTest = (testId) => {
    setSelectedTestId(testId);
}
//Delet Test
const handleDeleteTest = (testId) =>{
    
    setDeletedTestId(testId)
    if(testId){
        SetisDelete(true)
    }else{
        SetisDelete(false)
    }
}
const HandleDelete = () => {
    if(DeletedTestId){
        setIsLoading(true)
        const LabID = sessionStorage.getItem('LabID')
        const response = DeleteTest(DeletedTestId,LabID)
        if(response){
           setIsLoading(false);
           setTest_SubmissionStatus(true);
           toast.success("Data Deleted Successfully");
        }
    }
    else{
        toast.error("Test Id are missing")
    }
    SetisDelete(false);
  };
  //Handle Close Delete Modal
  const handleClose = () =>{
    SetisDelete(false)
  }
//-------------------Pagination
const [currentPage, setCurrentPage] = useState(0); // Start from page 1
const itemsPerPage = 8;

const indexOfLastItem = (currentPage + 1) * itemsPerPage;
const indexOfFirstItem = indexOfLastItem - itemsPerPage;
const limitedData = TesData.slice(indexOfFirstItem, indexOfLastItem);
const handlePageChange = (event, newPage) => {
setCurrentPage(newPage);
};

//-------------------------------

  const Gender = [
    {
      value: '0',
      label: 'All',
    },
    {
      value: 'male',
      label: 'Male',
    },
    {
      value: 'female',
      label: 'Female',
    },
   
  ];
 
//-------------------
const [modalIsOpen, setModalIsOpen] = useState(false);
  const openModal = () => {
    setModalIsOpen(true);
  };
  const closeModal = () => {
    setModalIsOpen(false);
  };

const onChange = (e) => {
    const { name, value } = e.target;
    setParams({
      ...Params,
      [name]: value,
    });
  };
  //---------\
  const GetSettingData = async () => {
    try {
        const response = await Get_Categorry(sessionStorage.getItem('LabID'));
        SetCateGorryData(response);
    } catch (error) {
        console.error("Error fetching settings:", error);
    }
};
const GetAgeGroup = async () => {
  try {
      const response = await Get_AgeGroup(sessionStorage.getItem('LabID'));
      SetAgegroupData(response);
  } catch (error) {
      console.error("Error fetching settings:", error);
  }
};

const GetSubCategory = async () => {
  try {
      const response = await Get_SubCategory(sessionStorage.getItem('LabID'));
      SetSubCategory(response);
  } catch (error) {
      console.error("Error fetching settings:", error);
  }
};

  useEffect(()=>{
    GetSettingData();
    GetAgeGroup();
    GetSubCategory();
    const User_Role = sessionStorage.getItem('User_Role');
    SetUserRole(User_Role)
 },[])

 //---------------Formate normal range and unit value -- function 
 const formatTextWithLineBreaks = (text) => {
  return text.split('\n').map((line, index) => (
    <React.Fragment key={index}>
      {line}
      {index < text.split('\n').length - 1 && <br />}
    </React.Fragment>
  ));
};

//---------------END ----------------
    
    return (
        <>
        {isLoading && <SpinnerModal  isLoading={isLoading}/>}
        <div className='container-fluid Container2'>
         <Paper sx={{ width: '100%', overflow: 'hidden' ,boxShadow:'rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px;',border:'1px solid lightgray'}}>
          <div style={{padding:'0px 15px'}} className='d-flex justify-content-between  mt-3'>
              <h5 className='common-FontWeight common-FontFamily'>Test Registration</h5>
            <button className='common-button' onClick={openModal}><SettingsRoundedIcon />Settings</button>
          </div>
          <hr></hr>
             <Box>
                <TestRegistration  TestID={selectedTestId}/>
            </Box>
            <Divider className='mt-3'></Divider>
            <div className='row p-2'> 
                    <div className='col-sm-2'>
                      <label className='common-Labels' >Main Catagory</label>
                      <select
                        class="form-select  common-Input"
                        id='CATEGORY_CODE'
                        name='CATEGORY_CODE'
                        required
                        onChange={onChange}
                        value={Params.CATEGORY_CODE}
                        >
                        <option value='0'>All</option>
                        {CategorryData.map((option) => (
                            <option key={option.categorY_CODE} value={option.categorY_CODE}>
                            {option.categorY_VALUE}
                            </option>
                            ))}
                    </select>
                  </div>
                  <div className='col-sm-2'>
                      <label className='common-Labels' >Sub Catagory</label>
                      <select
                        className="form-select  common-Input"
                        id='SUB_CATEGORY_CODE'
                        name='SUB_CATEGORY_CODE'
                        required
                        onChange={onChange}
                        value={Params.SUB_CATEGORY_CODE}
                        >
                        <option value='0'>All</option>
                        {SubCategory.map((option) => (
                            <option key={option.suB_CATEGORY_CODE} value={option.suB_CATEGORY_CODE}>
                            {option.suB_CATEGORY_VALUE}
                            </option>
                            ))}
                    </select>
                  </div>
                  <div className='col-sm-2'>
                    <label className='common-Labels' >Geneder Groups</label>
                    <select
                        className="form-select  common-Input"
                        id='GenderCategory'
                        name='GenderCategory'
                        required
                        onChange={onChange}
                        value={Params.GenderCategory}
                        >
                        {Gender.map((op) => (
                            <option key={op.value} value={op.value}>
                            {op.label}
                            </option>
                        ))}
                     </select>
                    </div>
                    <div className='col-sm-2'>
                     <label className='common-Labels' >Age Groups</label>
                      <select className="form-select  common-Input"
                            id='AGE_CODE'
                            name='AGE_CODE'
                            required
                            onChange={onChange}
                            value={Params.AGE_CODE}
                            >
                            <option value='0'>All</option>  
                           {AgegroupData.map((option) => (
                            <option key={option.agE_CODE} value={option.agE_CODE}>
                            {option.agE_VALUE}
                            </option>
                            ))}
                        </select>
                    </div>
                    <div className='col-sm-3 mt-4'>
                      <button onClick={RetriveTestData}  className=" common-Apply">Apply</button>
                    </div>
            </div>  
                <TableContainer   sx={{ maxHeight: '70vh',minHeight:'65vh' }}>
                    <Table  stickyHeader aria-label="sticky table" >
                        <TableHead>
                            <TableRow>
                            </TableRow>
                            <StyledTableRow>
                                <StyledTableCell className='TC1'>Category</StyledTableCell>
                                <StyledTableCell className='TC1'>Sub Category</StyledTableCell>
                                <StyledTableCell className='TC1'> Name</StyledTableCell>
                                <StyledTableCell className='TC1'> Reference Value</StyledTableCell>
                                <StyledTableCell className='TC1'> Price</StyledTableCell>
                                <StyledTableCell className='TC1'> Unit</StyledTableCell>
                                <StyledTableCell className='TC1'> Gender </StyledTableCell>
                                <StyledTableCell className='TC1'> Age group</StyledTableCell>
                                {UserRole ==="Admin" &&<StyledTableCell className='TC1'>Action</StyledTableCell>}

                            </StyledTableRow>
                        </TableHead>
                        <TableBody>
                            {limitedData.map((data, index) => (
                                <StyledTableRow  key={index}>
                                    <StyledTableCell className='TC'>{data.categorY_VALUE}</StyledTableCell>
                                    <StyledTableCell className='TC'>{data.suB_CATEGORY_VALUE}</StyledTableCell>
                                    <StyledTableCell className='TC'>{data.testName}</StyledTableCell>
                                    <StyledTableCell className='TC'>{formatTextWithLineBreaks(data.normalRange)}</StyledTableCell>
                                    <StyledTableCell className='TC'>{data.testPrice}-/pkr</StyledTableCell>
                                    <StyledTableCell className='TC'>{formatTextWithLineBreaks(data.unit)}</StyledTableCell>
                                    <StyledTableCell className='TC'>{data.genderCategory !='0'? data.genderCategory:'All'}</StyledTableCell>
                                    <StyledTableCell className='TC'>{(data.agE_VALUE != "" && data.agE_VALUE != "0") ? data.agE_VALUE :'All' }</StyledTableCell>

                                    <StyledTableCell className='TC'>
                                  {UserRole ==="Admin" && <button type='button'  className='Delete-Test-Button' onClick={() => handleDeleteTest(data.testID)}><DeleteForeverRoundedIcon sx={{fontSize:'18px'}} />Delete</button>}
                                  {UserRole ==="Admin" && <button type='button'className='Delete-Edit-Button'  onClick={() => handleEditTest(data.testID)}><EditRoundedIcon sx={{fontSize:'18px'}} />Edit</button>}
                                    </StyledTableCell>
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                    <TablePagination
                       component="div"
                        count={TesData.length}
                         page={currentPage}
                         onPageChange={handlePageChange}
                          rowsPerPage={itemsPerPage}
                       rowsPerPageOptions={[itemsPerPage]}
                       />
                </TableContainer>
                </Paper>
            <div>
             <div>
                <Dialog PaperProps={{ style: { borderRadius:'0px',padding:'10px'},}}    open={isDelete} keepMounted onClose={handleClose}   TransitionComponent={Transition}>
                  <div style={{textAlign:'end'}}>
                    <button className='common-cross-button' onClick={handleClose}>
                      <CloseRoundedIcon />
                    </button>
                  </div>
                       <DialogTitle style={{textAlign:'center'}} className='common-FontFamily'>
                          <CancelOutlinedIcon style={{fontSize:'100px',color:'red'}}/><br></br>
                          <h4>Are you sure!</h4>
                        </DialogTitle>
                    <DialogContent> 
                       <DialogContentText id="alert-dialog-slide-description">
                            <span>  Do you really want to delete these record?</span><br></br>
                            <span> This proccess cannot be undone.</span> 
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                    <button className='btn dialog-Cancel-button' onClick={handleClose}>Cancel</button>
                    <button className='btn dialog-Delete-button' onClick={HandleDelete}>Delete</button>
                    </DialogActions>
                </Dialog>
            </div>    
    </div>
 </div>
              <Dialog  PaperProps={{ style: { borderRadius:'0px'  }, }} className='common-modal'  open={modalIsOpen} keepMounted onClose={closeModal}  TransitionComponent={Transition}>
                    <DialogTitle className='common-FontFamily d-flex justify-content-between' sx={{fontWeight:600,backgroundColor:'#5bc5d0',color:'white'}}>
                        {"Test settings"}
                        <button style={{textAlign:'end',color:'white'}} className='common-cross-button' onClick={closeModal}>
                         <CloseRoundedIcon />
                        </button>
                     
                      </DialogTitle>
                    <Divider></Divider>
                    <DialogContent>
                        <TestSettings />
                    </DialogContent>
                </Dialog>
 </>
    
    )
}

export default TestEntry