import axios  from "axios";

// const baseURL = 'https://localhost:44397/api/'; //local
const baseURL = 'https://securelab.api.sehhasolutions.com/api/'; //prod


const apiServices = axios.create({
    baseURL,
})
export  const GetDashboard =async (data) =>{
    try{
        const response =  await apiServices.get(`Home/Dashboard?Labid=${data}`);
        return response.data;
    }
    catch(error){
        throw(error);
    }
} 
export  const Monthly_Lab_Data =async (data) =>{
    try{
        const response =  await apiServices.get(`Home/Monthly_Lab_Data?Labid=${data.LabID}&StartDate=${data.StartDate}&EndDate=${data.EndDate}`);
        return response.data;
    }
    catch(error){
        throw(error);
    }
} 