import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { GetSinglepatient } from '../Services/PatientServicces';
import { GetPrescription } from '../Services/TestServices';
import 'bootstrap/dist/css/bootstrap.min.css';
import Female from '../assets/female-Pofile.jpg';
import Male from '../assets/Male.jpg';
import { Divider, Paper, Tabs, Tab, Box, Typography, Dialog, DialogTitle, DialogContent, Slide, Checkbox, TextField, Button } from '@mui/material';
import SpinnerModal from '../Spinner/SpinnerModal';
import moment from 'moment';
import { Reports,Generate_Reports,GetDoctorInfo,GetFontStyles } from '../Services/TestServices';
import PrintReports from './PrintReports';
import { useReactToPrint } from 'react-to-print';
import { useApi } from '../ContextApi/ContextApi';
import { toast } from 'react-toastify';
import PrintRoundedIcon from '@mui/icons-material/PrintRounded';
import '../UI/PatientProfile.css';
const PatientProfile = () => {
  const[info,SetInfo] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [ProfileData, setProfileData] = useState({});
  const [PrescriptionData, setPrescriptionData] = useState([]);
  const [reportData, setReportData] = useState([]);
  const { isReportGenerate, setReport_generation_SubmissionStatus } = useApi();
  const [selectedTests, setSelectedTests] = useState([]);
  const[Fonts,SetFonts] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const componentRef = useRef();
  const { id } = useParams();
  const inputRefs = useRef([]);
  const [Comment,setComment] = useState('');
  const [QrCodeURl,SetQRCodeUrl] = useState([]);
  sessionStorage.setItem('PatientID', id);


//---------------------------------getting profile Data----------
useEffect(() => {
  GetprofileData();
}, [id]);


  const GetprofileData = async () => {
    setIsLoading(true);
    try {
      const LabID = sessionStorage.getItem('LabID');
      const data = await GetSinglepatient(id, LabID);
      setProfileData(data);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };
//----------------------------print functionality---------------------
  useEffect(() => {
    if (selectedTests.length > 0) {
      PrintReport();
    }
  }, [selectedTests]);
//------------------------print call -------------------------------
  const print = useReactToPrint({
    content: () => componentRef.current,
  });
//--------------------------------Getting Print Report data-----------------
const PrintReport = async () => {
  setIsLoading(true);
  try {
    const patientID = sessionStorage.getItem('PatientID');
    const LabID = sessionStorage.getItem('LabID');
    const IDs = selectedTests.map(x => x.labOrderID).join('&SelectedIDs=');

const Url = `https://securelab.api.sehhasolutions.com/api/Home/QRCodeData?PatientID=${patientID}&LabID=${LabID}&SelectedIDs=${IDs}`;
SetQRCodeUrl(Url);
    console.log("URL",Url)
    const data = await Reports(patientID, LabID,selectedTests);
    setReportData(data);
  } catch (error) {
    console.error('Failed to fetch reports:', error);
  } finally {
    setIsLoading(false);
  }
};

//-------------------END-----------------
  
//------------------calling all functions which should be called in render------------
  useEffect(() => {
    GetPresciptionData();
    setReport_generation_SubmissionStatus(false);
  }, [isReportGenerate]);
//-------------------------------Getting Prescription Data-------------------
  const GetPresciptionData = async () => {
    const LabID = sessionStorage.getItem('LabID');
    const response = await GetPrescription(id, LabID);
    setPrescriptionData(response);
  };

  //---------------------------handle checkbox-----------------
  // const handleCheckboxChange = (testID,LabORderID) => {
  //   debugger
  //   const LabID = sessionStorage.getItem('LabID');
  //   const patientID = sessionStorage.getItem('PatientID');
  //   const newTest = { LabID, patientID, testID ,LabORderID};

  //   setSelectedTests((prevSelectedTests) =>
  //     prevSelectedTests.some(test => test.testID === testID && test.labOrderid === LabORderID)
  //       ? prevSelectedTests.filter(test => test.testID !== testID && test.labOrderid !== LabORderID)
  //       : [...prevSelectedTests, newTest]
  //   );
  // };
  const handleCheckboxChange = (testID, labOrderID) => {
    const LabID = sessionStorage.getItem('LabID');
     const patientID = sessionStorage.getItem('PatientID');
     const newTest = { LabID, patientID, testID ,labOrderID};
    setSelectedTests(prevSelectedTests => {
      const testExists = prevSelectedTests.some(test => test.testID === testID && test.labOrderID === labOrderID);
  
      if (testExists) {
        return prevSelectedTests.filter(test => !(test.testID === testID && test.labOrderID === labOrderID));
      } else {
        return  [...prevSelectedTests, newTest]
      }
    });
  };
  
//----------------------END-------------------------------
//------------------------Handle select All test--------------
 // Handle select/deselect all
//  const handleSelectAllChange = (event) => {
//   const checked = event.target.checked;
//   setSelectAll(checked);
//   if (checked) {
//     // Select all tests
//     const allTests = PrescriptionData.map(test => ({
//       LabID: sessionStorage.getItem('LabID'),
//       patientID: sessionStorage.getItem('PatientID'),
//       testID: test.testID
//     }));
//     setSelectedTests(allTests);
//   } else {
//     // Deselect all tests
//     setSelectedTests([]);
//   }
// };
const handleSelectAllChange = (event) => {
  const checked = event.target.checked;
  setSelectAll(checked);

  if (checked) {
    const allTests = PrescriptionData.filter(test => test.testResult).map(test => ({
      LabID: sessionStorage.getItem('LabID'),
      patientID: sessionStorage.getItem('PatientID'),
      testID: test.testID,
      labOrderID: test.labOrderid
    }));
    setSelectedTests(allTests);
  } else {
    setSelectedTests([]);
  }
};

//---------------------------END----------------------
//======================= Group data by date and time========
const groupByDate = (data) => {
  return data.reduce((acc, item) => {
    const date = moment(item.createdAt).format('YYYY-MM-DD');
    if (!acc[date]) {
      acc[date] = [];
    }
    acc[date].push(item);
    return acc;
  }, {});
};
const groupedData = groupByDate(PrescriptionData);
//=============END=====================================

  //--------------------------handle result change and keydown and  save----------------------
  const handleKeyDown = (event, testID,LabOrderID,groupIndex, index) => {
    if (event.key === 'Enter') {
      event.preventDefault(); // Prevent form submission if inside a form
      handleSave(testID,LabOrderID,groupIndex, index);
    }
  };
  
  const handleInputChange = (testID, LabOrderID,value ) => {
    
    setPrescriptionData((prevPrescriptionData) =>
      prevPrescriptionData.map(test =>
        (test.testID === testID && test.labOrderid === LabOrderID )? { ...test, labOrderID_In_GR: LabOrderID, testResult: value } : test
      )
    );
  };
  
  const handleSave = async (testID,LabOrderID,groupIndex,index) => {
    setIsLoading(true);
    try {
      
      const testToSave = PrescriptionData.find(test => test.testID === testID && test.labOrderid === LabOrderID);
      const LabID = sessionStorage.getItem('LabID');
      const patientID = sessionStorage.getItem('PatientID');
  
      // Include patient ID and laboratory ID when saving data
        const savedData = {
          ...testToSave,
          LabID,
          patientID,
          reportID: testToSave.reportID || 0,
          Comment: Comment
        };
     if(savedData.testResult == '' || savedData.testResult == null){
          toast.error("Result required")
     }else{
      
    
      const Response = await Generate_Reports(savedData);
  
      if (Response) {
        toast.success("Report Generated Successfully");
        setReport_generation_SubmissionStatus(true);
        
        // Move focus to the next input field
        if (inputRefs.current[`${groupIndex}-${index + 1}`]) {
          inputRefs.current[`${groupIndex}-${index + 1}`].focus();
        } else if (inputRefs.current[`${groupIndex + 1}-0`]) {
          // If the next field doesn't exist, focus on the first input of the next group
          inputRefs.current[`${groupIndex + 1}-0`].focus();
        }
    }
  }
    } catch (error) {
      toast.error("Error occurred while saving data:", error);
    } finally {
      setIsLoading(false);
    }
  };
  
  //---------------------------End-----------------------

 


  //----------------------------------modal open ---------------
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const openModal = () => {
    setModalIsOpen(true);
  };
  const closeModal = () => {
    setModalIsOpen(false);
  };
  //----------------------tab change ---------------------
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
//-----------------END-------------
 //---------------Formate normal range and unit value -- function 
 const formatTextWithLineBreaks = (text) => {
  return text.split('\n').map((line, index) => (
    <React.Fragment key={index}>
      {line}
      {index < text.split('\n').length - 1 && <br />}
    </React.Fragment>
  ));
};

//---------------END ----------------
//---------------Get Doctor Info-------------
const DoctorInfo = async () =>{
  setIsLoading(true)
  try{
   
    const LabID = sessionStorage.getItem('LabID');
    const response  =await GetDoctorInfo(LabID);
    if(response != null){
      SetInfo(response)
    }
  }catch(error){
    toast.error(error)
  }finally{
    setIsLoading(false)
  }
}
//----------------End--------
//--------------Get Font Styles-----------
const Fontstyle = async () =>{
  setIsLoading(true)
  try{
   
    const LabID = sessionStorage.getItem('LabID');
    const response  =await GetFontStyles(LabID);
    console.log("FOnt",response)
    if(response != null){
      SetFonts(response)
    }
  }catch(error){
    toast.error(error)
  }finally{
    setIsLoading(false)
  }
}
useEffect(()=>{
  DoctorInfo();
  Fontstyle();
},[])
//------------End-------------------
//-------------Add-Comment-------------
const OnchangeComment = (e) =>{
    const value = e.target.value;
    setComment(value);
    console.log("comment change",value)
}
  return (
    <>
      {isLoading && <SpinnerModal isLoading={isLoading} />}
      <div style={{ display: 'none' }}>
        <PrintReports reportData={reportData} DRInfos={info} Fonts={Fonts} QrCodeURL={QrCodeURl} ref={componentRef} />
      </div>
      <div className='row p-4' style={{minHeight:'29rem'}}>
        <div className='col-3' style={{border:'1px solid lightgray'}}>
            <div className=' common-FontSize-small header '>
              <div style={{alignItems:'center',textAlign:'center'}}>
                   <div>
                      {ProfileData?.[0]?.sex === "male" ? (<img className='img' src={Male} alt="Male Profile" />) : (<img className='img' src={Female} alt="Female Profile" />)}
                    </div>
                    <div>
                      <h5>{ProfileData?.[0]?.patientName}</h5>
                      <span style={{ fontSize: 'small' }}>{ProfileData?.[0]?.age} year | {ProfileData?.[0]?.sex}</span>
                    </div>
              </div>
                    
                    <Box sx={{ padding: '5px', alignContent: 'center' }}>
                      <div className='d-flex justify-content-between'>
                        <span>MR No:</span>
                        <span className='Details_value'>{ProfileData?.[0]?.patientID}-{sessionStorage.getItem('LabID')}</span>
                      </div>
                      <div className='d-flex justify-content-between'>
                        <span>Referred by:</span><br />
                        <span className='Details_value'>{ProfileData?.[0]?.referredBy}</span>
                      </div>
                      <div className='d-flex justify-content-between'>
                        <span>Mobile:</span><br />
                        <span className='Details_value'>{ProfileData?.[0]?.contactNumber}</span>
                      </div>
                      <div className='d-flex justify-content-between'>
                        <span>Registration Date:</span><br />
                        <span className='Details_value'>{moment(ProfileData?.[0]?.created_at).format('YYYY-MM-DD')}</span>
                      </div>
                    </Box>

                     <div>
                        <span>Address:</span><br />
                        <span className='Details_value'>{ProfileData?.[0]?.address}</span>
                      </div>
                   
             </div> 
        </div>
        <div className='col-6 ProfileBody'>
        <Box className="d-flex justify-content-end" sx={{ padding: '5px', alignContent: 'center' }}>
              
             <button disabled={!selectedTests.length > 0} className='printButton'  onClick={print} >
                 <PrintRoundedIcon /> Print
                </button>
              </Box>
          {Object.entries(groupedData).map(([date, tests], groupIndex) => (
            <React.Fragment key={date}>
              <Box className="d-flex justify-content-start" sx={{ padding: '5px', alignContent: 'center' }}>
                <div className='common-FontSize-small'>
                  {moment(date).format('MMMM Do YYYY')}
                </div>
              </Box>
             
              <Box sx={{ maxHeight: '60vh', overflowY: 'auto' }}>
                <table className='Presc-table'>
                  <thead>
                    <tr style={{ background: '#5bc5d0', color: 'white' }}>
                      <th className='Presc_header'>
                      {groupIndex === 0 && (
                        <Checkbox
                          checked={selectAll}
                          onChange={handleSelectAllChange}
                          sx={{ color: 'white',padding:'0px', '&.Mui-checked': { color: 'white', padding:'0px'}, }}
                        />
                      )}  
                      </th>
                      <th className='Presc_header'>Test</th>
                      <th className='Presc_header'>Normal Range</th>
                      <th className='Presc_header'>Result</th>
                      <th className='Presc_header'>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                  {tests.map((testItem, index) => (
                        <tr key={testItem.testID}>
                          <td className='Tbody-column'>
                            <Checkbox
                              disabled={!testItem.testResult}
                              sx={{ color: '#5bc5d0', '&.Mui-checked': { color: '#5bc5d0' }, padding: '0px'}}
                              checked={selectedTests.some(test => test.testID === testItem.testID && test.labOrderID === testItem.labOrderid)}
                              onChange={() => handleCheckboxChange(testItem.testID , testItem.labOrderid)}
                            />
                          </td>
                          <td className='Tbody-column'>{testItem.testName}</td>
                          <td className='Tbody-column'>{formatTextWithLineBreaks(testItem.normalRange)}</td>
                          <td className='Tbody-column'>
                            <input
                              required
                              className='result-Textbox'
                              value={testItem.testResult || ''}
                              onChange={(e) => handleInputChange(testItem.testID, testItem.labOrderid, e.target.value)}
                              onKeyDown={(e) => handleKeyDown(e, testItem.testID, testItem.labOrderid, groupIndex, index)}
                              ref={(el) => (inputRefs.current[`${groupIndex}-${index}`] = el)}
                            />
                          </td>
                          <td className='DTC'>
                            {testItem.reportID ? (
                              <button
                                disabled={!testItem.testResult}
                                className='Result-Updatebutton'
                                size="small"
                                onClick={() => handleSave(testItem.testID, testItem.labOrderid, groupIndex, index)}
                              >
                                update
                              </button>
                            ) : (
                              <button
                                disabled={!testItem.testResult}
                                className='Result-Savebutton'
                                size="small"
                                onClick={() => handleSave(testItem.testID, testItem.labOrderid, groupIndex, index)}
                              >
                                save
                              </button>
                            )}
                          </td>
                        </tr>
                      ))}

                  </tbody>
                </table>
              </Box>
            </React.Fragment>
          ))}
        </div>

            <Box  className="col-3" style={{border:'1px solid lightgray'}}>
              <label className='common-Labels' >Add Comment</label>
                  <textarea
                  onChange={OnchangeComment}
                  className="form-control form-control common-Input"
                  >
                  </textarea>
              </Box>
      </div>
           
 
    </>
  );
};

export default PatientProfile;
